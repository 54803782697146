.Infographic-menu-option {
    display: inline-flex;
    overflow: hidden;
    float: left;
    height: 5em;
    width: 30%;
    padding: 20px;
    text-align: center;
    border-radius: 5px;
    position: relative;
    margin: 1.5%;
    font-size: 2.8vw;
    color: #333;
    text-align: center;
    line-height: 110%;
    font-weight: bold;
    cursor: pointer;
    text-shadow: 2px 2px 10px white;
    background-position: center;
    filter: grayscale(100%);
    background-size: cover;
    border: 2px solid white;
}

.Infographic-menu-option:hover {
    filter: grayscale(0%);
    border: 2px solid black;
    color: black;
}

.Infographic-menu-option-text-wrapper {
    display: table;
    height: 100%;
}

.Infographic-menu-option-text {
    display: table-cell;
    vertical-align: middle;
}

.back-btn {
    width: 8em;
    display: block;
    float: left;
}